export default {
    url: {
	api: 'https://api.zimu.bili.studio:44443',
        author: '//space.bilibili.com',
        clip: '//www.bilibili.com/video'
    },
    static: {
        url: 'https://static.bili.studio:8444'
    }
};
